<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="园区总览" name="one">
        <div class="overview_cont">
          <!-- 园区概况卡片 -->
          <el-card class="park">
            <div class="park_title">
              <h2>园区概况</h2>
            </div>
            <!-- 修改数据 -->
            <el-form :model="shihaoyuan_overview" ref="FormCont1" label-width="95px" size="small " class="parkFrom">
<!--              "can_rented_nm": 1,-->
<!--              "has_rented_nm": 1,-->
<!--              "can_renter_area": "1.00",-->
              <h4>公寓</h4>
              <el-row>
                <el-form-item label="可出租套数:" prop="industry_can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                  <el-input type="number" v-model.number="shihaoyuan_overview.can_rented_nm" autocomplete="off"></el-input>
                  <span> 套</span>
                </el-form-item>
                <el-form-item label="已签约套数:" prop="industry_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="shihaoyuan_overview.has_rented_nm" autocomplete="off"></el-input>
                  <span> 套</span>
                </el-form-item>
                <el-form-item label="出租率:">
                <el-input type="number" :disabled="true" v-model.number="gongyu_selling_rate" autocomplete="off"></el-input>
                <span> %</span>
              </el-form-item>
              </el-row>
              <h4>商业</h4>
              <el-row>
                <el-form-item label="可租面积:" prop="industry_can_rent_area" :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值'}]">
                  <el-input type="number" v-model.number="shihaoyuan_overview.can_renter_area"  autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="已签约面积:" prop="industry_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="shihaoyuan_overview.has_rented_area" autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="出租率:">
                  <el-input type="number" :disabled="true" v-model.number="areaChange_selling_rate" autocomplete="off"></el-input>
                  <span> %</span>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <!-- 空间配套 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>空间配套</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible = true">新增空间配套</el-button>
            <el-table :data="space_suppot" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="空间配套名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="简介介绍" align="center"></el-table-column>
              <el-table-column prop="address" label="所处地址" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeSpaceSuppot(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增空间配套" :visible.sync="dialogVisible" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef' ref="addFormRef" :model="space_suppot_data" :rules="addRules"  label-width="100px">
              <el-form-item label="封面：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="space_suppot_data.image" :src="space_suppot_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="名称:" prop="title">
                <el-input placeholder="输入空间配套名称" name='title' v-model="space_suppot_data.title"></el-input>
              </el-form-item>
              <el-form-item label="简介介绍:" prop="profile">
                <el-input type="textarea" placeholder="输入简介介绍" name='profile' v-model="space_suppot_data.profile"></el-input>
              </el-form-item>
              <el-form-item label="所处地址:" prop="address">
                <el-input placeholder="输入所处地址" name='address' v-model="space_suppot_data.address"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addSpaceSuppot">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑配套设施" :visible.sync="dialogVisibleEdit" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit' :model="get_space_suppot" :rules="addRules"  label-width="100px">
              <el-form-item label="企业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_space_suppot.image" :src="get_space_suppot.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="名称:" prop="title">
                <el-input placeholder="输入空间配套名称" name='title' v-model="get_space_suppot.title"></el-input>
              </el-form-item>
              <el-form-item label="简介介绍:" prop="profile">
                <el-input type="textarea" placeholder="输入简介介绍" name='profile' v-model="get_space_suppot.profile"></el-input>
              </el-form-item>
              <el-form-item label="所处地址:" prop="address">
                <el-input placeholder="输入所处地址" name='address' v-model="get_space_suppot.address"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit = false">取 消</el-button>
              <el-button type="primary" @click="editSpaceSuppot">确 定</el-button>
            </span>
          </el-dialog>
          <el-card class="park">
            <div class="park_title">
              <h2>人员进出情况</h2>
            </div>
            <el-table :data="all_table_show.personnel_access" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['personnel_access'].pageNum - 1) * queryInfo['personnel_access'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="employee_nm" label="员工（人次）" align="center"></el-table-column>
              <el-table-column prop="guest_nm" label="访客（人次）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'personnel_access')}"
              @current-change="(page)=>{handleCurrentChange(page,'personnel_access')}"
              :current-page="queryInfo['personnel_access'].pageNum"
              :page-size="queryInfo['personnel_access'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.personnel_access.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>车辆进出情况</h2>
            </div>
            <el-table :data="all_table_show.car_access" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['car_access'].pageNum - 1) * queryInfo['car_access'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="temporary_nm" label="临停（辆）" align="center"></el-table-column>
              <el-table-column prop="monthly_nm" label="月保（辆）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'car_access')}"
              @current-change="(page)=>{handleCurrentChange(page,'car_access')}"
              :current-page="queryInfo['car_access'].pageNum"
              :page-size="queryInfo['car_access'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.car_access.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>园区能耗使用情况</h2>
            </div>
            <el-table :data="all_table_show.energy_used" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['energy_used'].pageNum - 1) * queryInfo['energy_used'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="power" label="用电量（kw·h）" align="center"></el-table-column>
              <el-table-column prop="water" label="用水量（吨）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'energy_used')}"
              @current-change="(page)=>{handleCurrentChange(page,'energy_used')}"
              :current-page="queryInfo['energy_used'].pageNum"
              :page-size="queryInfo['energy_used'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.energy_used.length">
            </el-pagination>
          </el-card>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区运营" name="two">
        <div class="overview_cont">
          <el-card class="park">
            <div class="park_title">
              <h2>租户情况</h2>
            </div>
            <el-table :data="all_table_show.tenant_state" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['tenant_state'].pageNum - 1) * queryInfo['tenant_state'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="age" label="年龄" align="center"></el-table-column>
              <el-table-column prop="sex" label="性别" align="center"></el-table-column>
              <el-table-column prop="people_nm" label="人数" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'tenant_state')}"
              @current-change="(page)=>{handleCurrentChange(page,'tenant_state')}"
              :current-page="queryInfo['tenant_state'].pageNum"
              :page-size="queryInfo['tenant_state'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.tenant_state.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>社群活动</h2>
            </div>
            <el-form :model="shihaoyuan_overview" ref="FormCont4" label-width="100px" size="small " class="enterpriseFrom">
              <el-form-item label="活动数量:">
                <el-input v-model.number="shihaoyuan_overview.activities_nm" autocomplete="off"></el-input>
                <span> 场</span>
              </el-form-item>
              <el-form-item label="参与人数:">
                <el-input type="age" v-model.number="shihaoyuan_overview.activities_people_nm" autocomplete="off"></el-input>
                <span> 人</span>
              </el-form-item>
            </el-form>
          </el-card>
          <!-- 入驻商业 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>入驻商业</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible2 = true">新增入驻商业</el-button>
            <el-table :data="joining_business" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" align="center">
                <template>
                  商业
                </template>
              </el-table-column>
              <el-table-column prop="name" label="商户名称" align="center"></el-table-column>
              <el-table-column prop="company_name" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="商户简介" align="center"></el-table-column>
              <el-table-column prop="order_num" label="排序号" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog2(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeJoiningBusiness(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增入驻商业" :visible.sync="dialogVisible2" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef2' ref="addFormRef2" :model="joining_business_data" :rules="addRules2"  label-width="100px">
              <el-form-item label="商业Logo：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="joining_business_data.image" :src="joining_business_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商业名称:" prop="name">
                <el-input placeholder="输入商业名称" name='name' v-model="joining_business_data.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="joining_business_data.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="joining_business_data.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="joining_business_data.order_num"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningBusiness">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑配套设施" :visible.sync="dialogVisibleEdit2" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit2' :model="get_joining_business" :rules="addRules2"  label-width="100px">
              <el-form-item label="商业Logo：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_joining_business.image" :src="get_joining_business.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商业名称:" prop="name">
                <el-input placeholder="输入商业名称" name='name' v-model="get_joining_business.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="get_joining_business.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="get_joining_business.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="get_joining_business.order_num"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit2 = false">取 消</el-button>
                <el-button type="primary" @click="editJoiningBusiness">确 定</el-button>
            </span>
          </el-dialog>
          <el-card class="park">
            <div class="park_title">
              <h2>房型概况</h2>
            </div>
            <div class="operTitle_cont2">
              <span>房型</span><span>总数</span><span>已出租数量</span><span>空置数量</span><span>空置率</span>
            </div>
            <el-form ref="operateForm4" v-for="(item, index) in room_types" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
              <div class="oper_input">
                <el-input :class="item.type == '' ? 'red_error' : ''" @input="changeRoomTypes(index, item.id)" v-model="item.type" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.count == '' ? 'red_error' : ''" @input="changeRoomTypes(index, item.id)" v-model.number="item.count" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.has_rent_nm == '' ? 'red_error' : ''" @input="changeRoomTypes(index, item.id)" v-model.number="item.has_rent_nm" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input disabled size="small" :value="emptyNms[index]"></el-input>
              </div>
              <div class="oper_input">
                <el-input disabled  size="small" :value="emptyRate[index]"></el-input>
              </div>
              <div class="form_btn">
                <el-button v-if="index >= 1" class="numberInput"  @click="removeRoomTypes(item.id)" type="text" icon="el-icon-minus"></el-button>
                <el-button class="numberInput"  @click="addRoomTypes()" type="text" icon="el-icon-plus"></el-button>
              </div>
            </el-form>
          </el-card>
          <!-- 空间配套 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>房型介绍</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible3 = true">新增房型介绍</el-button>
            <el-table :data="room_report" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="房型名称" align="center"></el-table-column>
              <el-table-column prop="price" label="房型价位" align="center">
                <template slot-scope="scope">
                  {{scope.row.price}} 元/月起
                </template>
              </el-table-column>
              <el-table-column prop="profile" label="房型介绍" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog3(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeRoomReport(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增房型" :visible.sync="dialogVisible3" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef3' ref="addFormRef3" :model="room_report_data" :rules="addRules3"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange3"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="room_report_data.image" :src="room_report_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="房型名称:" prop="title">
                <el-input placeholder="输入房型名称" name='title' v-model="room_report_data.title"></el-input>
              </el-form-item>
              <el-form-item label="房型价位:" prop="price">
                <el-input type="number" placeholder="输入房型价位" name='price' v-model.number="room_report_data.price"></el-input>
              </el-form-item>
              <el-form-item label="房型介绍:" prop="profile">
                <el-input type="textarea" placeholder="输入房型介绍" name='profile' v-model="room_report_data.profile"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="addRoomReport">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑配套设施" :visible.sync="dialogVisibleEdit3" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit3' :model="get_room_report" :rules="addRules3"  label-width="100px">
              <el-form-item label="企业Logo：" prop="logo">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit3"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_room_report.image" :src="get_room_report.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="房型名称:" prop="title">
                <el-input placeholder="输入房型名称" name='title' v-model="get_room_report.title"></el-input>
              </el-form-item>
              <el-form-item label="房型价位:" prop="price">
                <el-input type="number" placeholder="输入房型价位" name='price' v-model.number="get_room_report.price"></el-input>
              </el-form-item>
              <el-form-item label="房型介绍:" prop="profile">
                <el-input type="textarea" placeholder="输入房型介绍" name='profile' v-model="get_room_report.profile"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit3 = false">取 消</el-button>
              <el-button type="primary" @click="editRoomReport">确 定</el-button>
            </span>
          </el-dialog>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      // 园区概况的数据
      shihaoyuan_overview: {},
      space_suppot: [],
      room_report: [],
      joining_business: [],
      room_types: [],
      changeRoomTypesTimer: [],
      all_table: {
        energy_used: [],
        personnel_access: [],
        car_access: [],
        tenant_state: []
      },
      all_table_show: {
        energy_used: [],
        personnel_access: [],
        car_access: [],
        tenant_state: []
      },
      dialogVisible: false,
      dialogVisibleEdit: false,
      dialogVisible2: false,
      dialogVisibleEdit2: false,
      dialogVisible3: false,
      dialogVisibleEdit3: false,
      space_suppot_data: {
        image: '',
        title: '',
        profile: '',
        address: ''
      },
      get_space_suppot: {
        image: '',
        title: '',
        profile: '',
        address: ''
      },
      room_report_data: {
        image: '',
        title: '',
        price: '',
        profile: ''
      },
      get_room_report: {
        image: '',
        title: '',
        price: '',
        profile: ''
      },
      joining_business_data: {
        image: '',
        name: ''
      },
      get_joining_business: {
        image: '',
        name: ''
      },
      addRules: {
        title: [
          { required: true, message: '请输入空间配套名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入简介介绍', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入所处地址', trigger: 'blur' }
        ]
      },
      addRules2: {
        image: [
          { required: true, message: '请输入商业LOGO', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入商业名称', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      },
      addRules3: {
        image: [
          { required: true, message: '请选择封面', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入房型名称', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入房型价位', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入房型介绍', trigger: 'blur' }
        ]
      },
      // 选择到第几个模块 first/second
      activeName: 'one',
      queryInfo: {
        energy_used: {
          pageNum: 1,
          pageSize: 10
        },
        personnel_access: {
          pageNum: 1,
          pageSize: 10
        },
        car_access: {
          pageNum: 1,
          pageSize: 10
        },
        tenant_state: {
          pageNum: 1,
          pageSize: 10
        }
      }
    }
  },
  computed: {
    gongyu_selling_rate () {
      return Math.round(this.shihaoyuan_overview.has_rented_nm / this.shihaoyuan_overview.can_rented_nm * 1 * 100)
    },
    areaChange_selling_rate () {
      return Math.round(this.shihaoyuan_overview.has_rented_area / this.shihaoyuan_overview.can_renter_area * 100)
    },
    emptyNms () {
      const emptyNms = []
      this.room_types.forEach((obj, key) => {
        emptyNms[key] = obj.count - obj.has_rent_nm
      })
      return emptyNms
    },
    emptyRate () {
      const emptyNms = []
      this.room_types.forEach((obj, key) => {
        emptyNms[key] = ((obj.count - obj.has_rent_nm) / obj.count * 100).toFixed(2) + '%'
      })
      return emptyNms
    }
  },
  async created () {
    // 获取园区概况的数据
    const { data: res } = await this.$http.get('shihaoyuan')
    this.shihaoyuan_overview = res.data
    this.getSpaceSuppotList()
    this.getJoiningBusinessList()
    this.getRoomReportList()
    this.getRoomTypesList()
    this.getAllTable()
  },
  methods: {
    async getAllTable () {
      const { data: res } = await this.$http.get('shihaoyuan_all_table')
      this.all_table = res.data
      Object.keys(this.all_table).forEach(key => {
        this.all_table_show[key] = this.all_table[key].slice(0, 10)
      })
    },
    // 保存数据
    async submitForm () {
      const res = await this.$http.post('shihaoyuan_update', {
        can_rented_nm: this.shihaoyuan_overview.can_rented_nm,
        has_rented_nm: this.shihaoyuan_overview.has_rented_nm,
        can_renter_area: this.shihaoyuan_overview.can_renter_area,
        has_rented_area: this.shihaoyuan_overview.has_rented_area,
        activities_nm: this.shihaoyuan_overview.activities_nm,
        activities_people_nm: this.shihaoyuan_overview.activities_people_nm
      })
      if (res) this.$message({ message: '保存成功', type: 'success' })
    },
    onUploadChange (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.space_suppot_data.image = e.currentTarget.result
      }
    },
    onUploadChange2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_business_data.image = e.currentTarget.result
      }
    },
    onUploadChange3 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.room_report_data.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_space_suppot.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_business.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit3 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_room_report.image = e.currentTarget.result
      }
    },
    async getSpaceSuppotList () {
      const { data: res } = await this.$http.get('shihaoyuan_space_suppot')
      this.space_suppot = res.data
    },
    async getRoomReportList () {
      const { data: res } = await this.$http.get('shihaoyuan_room_report')
      this.room_report = res.data
    },
    async getJoiningBusinessList () {
      const { data: res } = await this.$http.get('shihaoyuan_joining_business')
      this.joining_business = res.data
    },
    async getRoomTypesList () {
      const { data: res } = await this.$http.get('shihaoyuan_room_types')
      this.room_types = res.data
    },
    addSpaceSuppot () {
      const data = new FormData(document.getElementById('addFormRef'))
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('shihaoyuan_space_suppot', data)
        this.dialogVisible = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getSpaceSuppotList()
      })
    },
    addRoomReport () {
      const data = new FormData(document.getElementById('addFormRef3'))
      this.$refs.addFormRef3.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('shihaoyuan_room_report', data)
        this.dialogVisible3 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getRoomReportList()
      })
    },
    addJoiningBusiness () {
      const data = new FormData(document.getElementById('addFormRef2'))
      this.$refs.addFormRef2.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('shihaoyuan_joining_business', data)
        this.dialogVisible2 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningBusinessList()
      })
    },
    async addRoomTypes () {
      const { data: res } = await this.$http.post('shihaoyuan_room_types')
      this.room_types.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async editSpaceSuppot () {
      const data = new FormData(document.getElementById('addFormRefEdit'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`shihaoyuan_space_suppot/${this.get_space_suppot.id}`, data)
      this.dialogVisibleEdit = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getSpaceSuppotList()
    },
    async editRoomReport () {
      const data = new FormData(document.getElementById('addFormRefEdit3'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`shihaoyuan_room_report/${this.get_room_report.id}`, data)
      this.dialogVisibleEdit3 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getRoomReportList()
    },
    async editJoiningBusiness () {
      const data = new FormData(document.getElementById('addFormRefEdit2'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`shihaoyuan_joining_business/${this.get_joining_business.id}`, data)
      this.dialogVisibleEdit2 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningBusinessList()
    },
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`shihaoyuan_space_suppot/${id}`)
      this.get_space_suppot = res.data
      this.get_space_suppot.image = this.serverAddress + res.data.image
    },
    async showEditDialog2 (id) {
      this.dialogVisibleEdit2 = true
      const { data: res } = await this.$http.get(`shihaoyuan_joining_business/${id}`)
      this.get_joining_business = res.data
      this.get_joining_business.image = this.serverAddress + res.data.image
    },
    async showEditDialog3 (id) {
      this.dialogVisibleEdit3 = true
      const { data: res } = await this.$http.get(`shihaoyuan_room_report/${id}`)
      this.get_room_report = res.data
      this.get_room_report.image = this.serverAddress + res.data.image
    },
    async removeSpaceSuppot (id) {
      const { data: res } = await this.$http.delete(`shihaoyuan_space_suppot/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getSpaceSuppotList()
    },
    async removeRoomReport (id) {
      const { data: res } = await this.$http.delete(`shihaoyuan_room_report/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getRoomReportList()
    },
    async removeJoiningBusiness (id) {
      const { data: res } = await this.$http.delete(`shihaoyuan_joining_business/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningBusinessList()
    },
    async removeRoomTypes (id) {
      const { data: res } = await this.$http.delete(`shihaoyuan_room_types/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getRoomTypesList()
    },
    changeRoomTypes (key, id) {
      if (this.changeRoomTypesTimer[key]) {
        clearTimeout(this.changeRoomTypesTimer[key])
        this.changeRoomTypesTimer[key] = null
      }
      this.changeRoomTypesTimer[key] = setTimeout(async () => {
        const { data: res } = await this.$http.post(`shihaoyuan_room_types/${id}`, {
          type: this.room_types[key].type,
          count: this.room_types[key].count,
          has_rent_nm: this.room_types[key].has_rent_nm,
          _method: 'PUT'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      }, 1000)
    },
    // 选项卡切换
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    handleSizeChange (newSize, key) {
      this.queryInfo[key].pageSize = newSize
      this.queryInfo[key].pageNum = 1
      this.all_table_show[key] = this.all_table[key].slice(0, this.queryInfo[key].pageSize)
    },
    handleCurrentChange (newPage, key) {
      this.queryInfo[key].pageNum = newPage
      const start = (this.queryInfo[key].pageNum - 1) * this.queryInfo[key].pageSize
      const end = this.queryInfo[key].pageNum * this.queryInfo[key].pageSize
      this.all_table_show[key] = this.all_table[key].slice(start, end)
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont{
  padding: 1rem;
  .park,.enterprise{
    margin-bottom: 1rem;
    .park_title,.enterprise_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .rowClass{
      flex-direction: column;
    }
    .el-row{
      display: flex;
      width: 100%;
    }
    .parkFrom,.enterpriseFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        .el-input{
          width: 75%;
        }
      }
    }
  }
  .save_btn{
    display: flex;
    justify-content: center;
    .el-button--primary{
      background-color: #015478;
      border-color: #015478;
      padding: 12px 60px;
    }
  }
}
.situation_cont{
  .situation{
    margin-bottom: 1rem;
    .title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .table{
      margin: 1rem 0;
    }
  }
}
.el-textarea{
  margin-top: 20px;
  width: 80%;
}
.facilities_btn{
  margin: 1rem;
}

.joiningBusinessBox{
  display: flex;
  flex-wrap: wrap;
}

.joiningBusiness{
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.joiningBusiness img{
  max-width: 200px;
  max-height: 200px;
}
.joingTitle{
  text-align: center;
  padding: 5px 0;
}
.joingBtns{
  display: flex;
  justify-content: center;
}

.operTitle_cont2{
  width: 52%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: 17%;
    font-size: 14px;
    color: #606266;
    text-align: center;
    padding: 0.5rem 0;
  }
}

.operateForm{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-form-item,.oper_input{
    width: 15%;
    text-align: center;
  }
  .form_btn{
    width: 10%;
    display: flex;
    font-size: 1.25rem;
  }
}
</style>
